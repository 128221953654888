<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="书单名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="书单分类" prop="menu_ids">
        <el-select v-model="info.menu_ids" placeholder="请选择分类" multiple style="width: 100%">
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="书单图" prop="imgs">
        <SelectImgs :selectNum="5" :selectData="info.imgs" :selectFunc="imgChoose" :deleteFunc="deleteFunc"></SelectImgs>
        <span style="color: #ccc;">最多5张图，第一张图为默认图</span>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" rows="4" v-model="info.intro" placeholder="请输入简介"></el-input>
      </el-form-item>
      <el-form-item label="书单评语">
        <el-input type="textarea" rows="4" v-model="info.content" placeholder="请输入书单评语"></el-input>
      </el-form-item>
      <el-form-item label="关联商品">
        <el-button type="primary" @click="showModel=true">选择商品</el-button>
        <div class="goods-box">
          <div class="item" v-for="(item,index) in info.goods" :key="index">
            <img class="img" :src="item.img">
            <div class="name">{{item.title}}</div>
            <div class="close" @click="delGoods(index)"><i class="el-icon-error"></i></div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="关联标签">
        <el-input placeholder="请输入内容" v-model="tagName" class="input-with-select" style="width: 180px">
          <el-button slot="append" @click="addTag">添加</el-button>
        </el-input>
        <div class="tags">
          <div class="item" v-for="(item,index) in info.tags" :key="index">
            <el-input placeholder="请输入内容" v-model="info.tags[index]" class="input-with-select" style="width: 180px">
              <el-button slot="append" @click="delTag(index)">删除</el-button>
            </el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="选择商品" :visible.sync="showModel" custom-class="dialog">
      <goods-list :type="2" :goodsType="1" :selectFunc="chooseGoods"></goods-list>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import GoodsList from "@/components/GoodsList";
export default {
  data() {
    return {
      showModel: false,
      tableHeader: [],
      tableData: [],
      menuList: [],
      tableRandKey: 1,
      tagName: '',
      info: {
        id: 0,
        menu_ids: [],
        imgs: [],
        intro: '',
        content: '',
        goods: [],
        tags: [],
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          {required: true, message: '请输入书单名称', trigger: 'blur'}
        ],
        menu_ids: [
          {required: true, message: '请选择分类', trigger: 'blur'}
        ]
      }
    };
  },
  created() {
    this.getMenuList()
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
  },
  components: {
    SelectImgs,
    GoodsList
  },
  computed: {},
  methods: {
    addTag() {
      if(this.tagName=='') {
        this.fail('请输入标签名')
      } else {
        this.info.tags.push(this.tagName)
        this.tagName = ''
      }
    },
    delTag(index) {
      this.info.tags.splice(index,1)
    },
    imgChoose(item,idx) {
      var _this = this
      var pics = this.info.imgs
      item.forEach(function (child) {
        if(_this.info.imgs.length < 5) {
          pics.push(child.pic)
        }
      })
      this.info.imgs = pics
    },
    deleteFunc(idx) {
      var imgs = JSON.parse(JSON.stringify(this.info.imgs))
      imgs.splice(idx,1)
      this.info.imgs = imgs
    },
    chooseGoods(goods) {
      goods.forEach(item => {
        this.info.goods.push(item)
      })
      this.showModel = false
    },
    delGoods(index) {
      this.info.goods.splice(index,1)
    },
    getMenuList() {
      this.$api.goods.menuList({type:4,menu_type:2}, res => {
        if (res.errcode == 0) {
          this.menuList = res.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.goods.bookListInfo({id: that.info.id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = JSON.parse(JSON.stringify(this.info))
          var goods = []
          if(param.goods.length>0) {
            param.goods.forEach(item => {
              goods.push(item.id)
            })
          }
          param.goods = goods
          if (this.info.id == 0) {
            this.$api.goods.bookListAdd(param, function (res) {
              if (res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.goods.bookListEdit(param, function (res) {
              if (res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.tags .item {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
